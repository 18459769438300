import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card, Col, Modal, Row, Table } from "react-bootstrap"
import { useHistory, useLocation, useParams } from "react-router-dom"
import * as Yup from "yup"
import { SuratJalanApi } from "../../../api"
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  TextArea
} from "../../../components"
import {
  DateConvert,
  DecimalConvert,
  GuestMiddleware
} from "../../../utilities"

const DetailSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const { state } = useLocation()
  const no_surat_jalan = state?.no_surat_jalan
  const tab = state?.tab ?? ""
  const { id } = useParams()
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [dataDeliveryOrder, setDataDeliveryOrder] = useState([])
  const [approveStatus, setApproveStatus] = useState("V")
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: ""
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    SuratJalanApi.getSingle({ no_surat_jalan: no_surat_jalan })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V"
        setDataDeliveryOrder(data.data)
        setApproveStatus(behavior.toUpperCase())
      })
      .catch(() => {
        setIsFetchingFailed(true)
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!"
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const whenNoSuratJalan = () => {
    setIsPageLoading(false)
    setIsFetchingFailed(true)
  }

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10)

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV"
      }

      if (modalConfig.type === "approve") {
        return "APP"
      }

      if (modalConfig.type === "reject") {
        return "REJ"
      }
    }

    const finalValues = {
      no_transaksi: no_surat_jalan,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataDeliveryOrder.detail.baseline
    }

    SuratJalanApi.approve(finalValues)
      .then(() =>
        history.push("/human-resource/approval/surat-jalan", {
          alert: {
            show: true,
            variant: "primary",
            text: `Approval berhasil disimpan!`
          }
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!"
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false
        })
        getInitialData()
      })
  }

  useEffect(() => {
    setNavbarTitle("Approval Surat Jalan")
    getInitialData()
    // no_surat_jalan ? getInitialData() : whenNoSuratJalan();

    return () => {
      setIsPageLoading(false)
    }
  }, [])

  const InfoSection = () => {
    const InfoItem = ({ title, value, isPaddingTop = false }) => (
      <tr>
        <td
          className="align-top"
          style={isPaddingTop ? { paddingTop: "10px" } : {}}>
          {title}
        </td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    )

    return (
      <Row>
        <Col md>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="No. Sales Order"
                value={
                  dataDeliveryOrder?.detail?.no_sales_order
                    ? dataDeliveryOrder?.detail?.no_sales_order
                    : "-"
                }
              />
              <InfoItem
                title="Tgl. Sales Order"
                value={
                  dataDeliveryOrder?.detail?.tgl_sales_order
                    ? DateConvert(
                        new Date(dataDeliveryOrder?.detail?.tgl_sales_order)
                      ).detail
                    : "-"
                }
              />
              <InfoItem
                title="Customer"
                value={
                  dataDeliveryOrder?.detail?.nama_customer
                    ? dataDeliveryOrder?.detail?.nama_customer
                    : "-"
                }
              />
              <InfoItem
                title="Sales"
                value={
                  dataDeliveryOrder?.detail?.nama_sales
                    ? dataDeliveryOrder?.detail?.nama_sales
                    : "-"
                }
              />
              <InfoItem
                title="Proyek"
                value={
                  dataDeliveryOrder?.detail?.nama_proyek
                    ? dataDeliveryOrder?.detail?.nama_proyek
                    : "-"
                }
              />
              <InfoItem
                title="Tgl. Delivery Order"
                value={
                  dataDeliveryOrder?.detail?.tgl_delivery_order
                    ? DateConvert(
                        new Date(dataDeliveryOrder?.detail?.tgl_delivery_order)
                      ).detail
                    : "-"
                }
              />
              <InfoItem
                title="No. Delivery Order"
                value={
                  dataDeliveryOrder?.detail?.no_delivery_order
                    ? dataDeliveryOrder?.detail?.no_delivery_order
                    : "-"
                }
              />
              <InfoItem
                title="Tgl. Surat Jalan"
                value={
                  dataDeliveryOrder?.detail?.tgl_surat_jalan
                    ? DateConvert(
                        new Date(dataDeliveryOrder?.detail?.tgl_surat_jalan)
                      ).detail
                    : "-"
                }
              />
              <InfoItem
                title="No. Surat Jalan"
                value={
                  dataDeliveryOrder?.detail?.no_surat_jalan
                    ? dataDeliveryOrder?.detail?.no_surat_jalan
                    : "-"
                }
              />
              <InfoItem
                title="Petugas Pengiriman"
                value={
                  dataDeliveryOrder?.detail?.nama_sopir
                    ? dataDeliveryOrder?.detail?.nama_sopir
                    : "-"
                }
              />
              <InfoItem
                title="Armada Pengiriman"
                value={`${dataDeliveryOrder?.detail?.plat_nomor} - ${dataDeliveryOrder?.detail?.nama_item_aset}`}
              />
            </tbody>
          </table>
        </Col>
      </Row>
    )
  }

  const TableDOSection = () => {
    const dataTableDO = dataDeliveryOrder?.detail?.detail
      ? dataDeliveryOrder.detail.detail
      : []

    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          fontSize: "14px"
        }}>
        {props.children}
      </th>
    )

    const Td = (props) => (
      <td
        {...props}
        className={props.className}
        style={{
          ...props.style,
          fontSize: "14px"
        }}>
        {props.children}
      </td>
    )

    return (
      <>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              <Th className="text-center" style={{ width: 30 }}>
                No
              </Th>
              <Th className="text-center" style={{ width: 100 }}>
                Kode Barang
              </Th>
              <Th className="text-center">Item Barang</Th>
              <Th className="text-center">Qty. DO</Th>
              <Th className="text-center">Qty. Terkirim</Th>
              <Th className="text-center">Qty. Sisa DO</Th>
              <Th className="text-center">Qty. Surat Jalan</Th>
              <Th className="text-center">Satuan Jual</Th>
              <Th className="text-center">Qty. Satuan Pakai</Th>
              <Th className="text-center">Satuan Pakai</Th>
            </tr>
          </thead>
          <tbody>
            {dataTableDO.length > 0 ? (
              dataTableDO.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td className="px-1">{val.kode_barang}</Td>
                  <Td className="px-1">{val.nama_barang}</Td>
                  <Td className="px-1 text-right">
                    {DecimalConvert(val.qty_do ?? 0).getWithComa}
                  </Td>
                  <Td className="px-1 text-right">
                    {DecimalConvert(val.qty_terkirim ?? 0).getWithComa}
                  </Td>
                  <Td className="px-1 text-right">
                    {
                      DecimalConvert(val.qty_do ?? 0 - val.qty_terkirim ?? 0)
                        .getWithComa
                    }
                  </Td>
                  <Td className="px-1 text-right">
                    {DecimalConvert(val.qty_sj ?? 0).getWithComa}
                  </Td>
                  <Td className="px-1">{val.satuan_jual}</Td>
                  <Td className="px-1 text-right">
                    {DecimalConvert(val.qty_satuan_pakai ?? 0).getWithComa}
                  </Td>
                  <Td className="px-1">{val.nama_satuan}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    )
  }

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }>
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    )
  }

  const FormCard = ({ formik }) => {
    const { values, errors, touched, dirty, validateForm, handleChange } =
      formik
    const dataPengaju = dataDeliveryOrder?.approval
      ? dataDeliveryOrder.approval
      : []

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Detail Surat Jalan</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPengaju.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER"
                      ? `Pemeriksa ${val.approval_level}`
                      : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          {dataPengaju.length > 1 && <hr />}
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Detail Surat Jalan
                              </span>
                            )
                          })
                        }

                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Detail Surat Jalan
                              </span>
                            )
                          })
                        }

                        validateForm()
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Detail Surat Jalan
                              </span>
                            )
                          })
                        }

                        validateForm()
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "revise",
                            title: (
                              <span className="text-warning">
                                Revise Detail Surat Jalan
                              </span>
                            )
                          })
                        }

                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Detail Surat Jalan
                              </span>
                            )
                          })
                        }

                        validateForm()
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    )
  }

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Surat Jalan</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false
              })
            }
          />

          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableDOSection />
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{
            catatan: ""
          }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan catatan")
          })}
          onSubmit={formSubmitHandler}>
          {(formik) => (
            <>
              <GuestMiddleware>
                <FormCard formik={formik} />
              </GuestMiddleware>
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  )
}

export default DetailSuratJalan
