import {
  ApprovalStatusButton,
  CRUDLayout,
  Pagination,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed
} from "components"
import {
  ButtonCancel,
  ButtonCreate,
  ButtonDetail,
  ButtonFilter,
  ButtonUpdate,
  DataStatus,
  InputSearch
} from "components2"
import { Formik } from "formik"
import { useModalConfirm } from "hooks2"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import { ButtonGroup, Modal } from "react-bootstrap"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { dateConvert, rupiahConvert, tableNumber } from "utilities2"
import RealisasiPettyCashApi from "./__RealisasiPettyCashApi__"
import {
  ModalFilter,
  RealisasiPettyCashContent
} from "./__RealisasiPettyCashComps__"
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  STATUS_APPROVAL
} from "./__RealisasiPettyCashUtils__"
import { GuestMiddleware } from "utilities"

const RealisasiPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory()
  const modalConfirm = useModalConfirm()
  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10
  })

  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    per_page: 10,
    tgl_penerimaan_petty_cash_start: undefined,
    tgl_penerimaan_petty_cash_end: undefined,
    tgl_realisasi_petty_cash_start: undefined,
    tgl_realisasi_petty_cash_end: undefined,
    id_unit_produksi: undefined,
    status_approval: undefined,
    active: false
  })

  const [modalFilter, setModalFilter] = useState(false)

  const [modal, setModal] = useState({
    type: "CREATE", // CREATE | UPDATE | DETAIL
    show: false,
    data: {}
  })

  const getPengajuanPettyCash = useQuery(
    ["realisasi_petty_cash", "list", pagination, filter],
    () => RealisasiPettyCashApi.getList({ ...pagination, ...filter })
  )

  const createPengajuanPettyCash = useMutation((data) =>
    RealisasiPettyCashApi.create(data)
  )

  const updatePengajuanPettyCash = useMutation((data) =>
    RealisasiPettyCashApi.update(data)
  )

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined

    setPagination({
      ...pagination,
      q: searchKey
    })
  }

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter)

  const onCreateButtonClickHandler = () =>
    history.push(
      "/human-resource/transaksi/realisasi-petty-cash/penerimaan-petty-cash"
    )

  const onDetailButtonClickHandler = (id) =>
    history.push(`/human-resource/transaksi/realisasi-petty-cash/detail/${id}`)

  const onUpdateButtonClickHandler = (id) =>
    history.push(`/human-resource/transaksi/realisasi-petty-cash/ubah/${id}`)

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 })

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value })

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)

    await modalConfirm.trigger({
      size: "md",
      type: modal.type === "CREATE" ? "create" : "update",
      component: (
        <Formik initialValues={values}>
          <RealisasiPettyCashContent action="DETAIL" />
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading()

        if (modal.type === "CREATE") {
          return await createPengajuanPettyCash
            .mutateAsync(formSubmitValueMapper(values), resetForm)
            .then(() => {
              modalConfirm.infoSuccess({
                size: "md",
                onHide: () => {
                  modalConfirm.close()
                  dismissModal(resetForm)
                }
              })
              getPengajuanPettyCash.refetch()
            })
            .catch(() => modalConfirm.infoError())
        }

        await updatePengajuanPettyCash
          .mutateAsync(formSubmitValueMapper(values))
          .then(() => {
            modalConfirm.infoSuccess({
              size: "md",
              onHide: () => {
                modalConfirm.close()
                dismissModal(resetForm)
              }
            })
            getPengajuanPettyCash.refetch()
          })
          .catch(() => modalConfirm.infoError())
      },
      onHide: () => modalConfirm.close()
    })
  }

  const dismissModal = (resetForm) => {
    resetForm()
    setModal({ data: null, show: false, type: "" })
  }

  const formInitialValuesFilter = {
    tgl_penerimaan_petty_cash_start: filter?.tgl_penerimaan_petty_cash_start,
    tgl_penerimaan_petty_cash_end: filter?.tgl_penerimaan_petty_cash_end,
    tgl_realisasi_petty_cash_start: filter?.tgl_realisasi_petty_cash_start,
    tgl_realisasi_petty_cash_end: filter?.tgl_realisasi_petty_cash_end,
    id_unit_produksi: filter?.id_unit_produksi,
    status_approval: filter?.status_approval
  }

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1
    })
    onFilterButtonClickkHandler()
  }

  useEffect(() => setNavbarTitle("Realisasi Petty Cash"), [])

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
            <ButtonFilter
              size="sm"
              className="text-nowrap ml-3"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <GuestMiddleware>
            <ButtonCreate size="md" icon onClick={onCreateButtonClickHandler} />
          </GuestMiddleware>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPengajuanPettyCash.isLoading || getPengajuanPettyCash.isError ? (
        <DataStatus
          loading={getPengajuanPettyCash.isLoading}
          text={
            getPengajuanPettyCash.isLoading
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Realisasi Petty Cash
            </small>
          </div>
          <Table>
            <thead>
              <tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Realisasi Petty Cash
                </Th>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Penerimaan Petty Cash
                </Th>
                <Th style={{ minWidth: 300 }} className="p-1">
                  Unit Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Jumlah Penegeluaran Petty Cash
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Sisa Petty Cash
                </Th>
                <Th style={{ width: 10 }} className="p-1">
                  Status Verifikasi
                </Th>
              </tr>
            </thead>
            <tbody>
              {getPengajuanPettyCash?.data?.data?.length > 0 ? (
                getPengajuanPettyCash?.data?.data?.map((e, index) => (
                  <tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index
                      })}
                    </TdFixed>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          variant="primary"
                          onClick={() =>
                            onDetailButtonClickHandler(
                              e.id_realisasi_petty_cash
                            )
                          }
                        />
                        <GuestMiddleware>
                          {e.status_approval === "REV" && (
                            <ButtonUpdate
                              icon
                              noText
                              variant="success"
                              onClick={() =>
                                onUpdateButtonClickHandler(
                                  e.id_realisasi_petty_cash
                                )
                              }
                            />
                          )}
                        </GuestMiddleware>
                      </ButtonGroup>
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_realisasi_petty_cash)
                        )}
                      </div>
                      <div>{e.no_realisasi_petty_cash}</div>
                    </Td>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_penerimaan_petty_cash)
                        )}
                      </div>
                      <div>{e.no_penerimaan_petty_cash}</div>
                    </Td>
                    <Td>{e.nama_unit_produksi}</Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(
                        e.nominal_realisasi_petty_cash
                      )}
                    </Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(e.sisa_petty_cash)}
                    </Td>
                    <Td textCenter>
                      <ApprovalStatusButton
                        variant={
                          STATUS_APPROVAL[e.status_approval ?? "PEN"]?.variant
                        }>
                        {STATUS_APPROVAL[e.status_approval ?? "PEN"]?.label}
                      </ApprovalStatusButton>
                    </Td>
                  </tr>
                ))
              ) : (
                <tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
            </tbody>
          </Table>

          {getPengajuanPettyCash?.data?.data?.length > 0 && (
            <Pagination
              dataLength={pagination?.per_page}
              dataNumber={
                pagination?.page * pagination?.per_page -
                pagination?.per_page +
                1
              }
              dataPage={
                pagination?.dataCount < pagination?.per_page
                  ? pagination?.dataCount
                  : pagination?.page * pagination?.per_page
              }
              dataCount={getPengajuanPettyCash?.data?.data_count}
              currentPage={pagination?.page}
              totalPage={getPengajuanPettyCash?.data?.total_page}
              onPaginationChange={onPaginationChange}
              onDataLengthChange={onPaginationDataLengthChange}
            />
          )}
        </>
      )}

      {/* Modal Create | Update | Detail */}
      <Formik
        enableReinitialize
        initialValues={formInitialValues(modal?.data, modal?.type)}
        validationSchema={formValidationSchema}
        onSubmit={onSubmitForm}>
        {(formik) => (
          <Modal
            size="xl"
            show={modal.show}
            onHide={() => dismissModal(formik.resetForm)}>
            <Modal.Header closeButton>
              <span>{`${
                modal.type === "CREATE"
                  ? "Tambah"
                  : modal.type === "UPDATE"
                  ? "Ubah"
                  : "Detail"
              } Pengajuan Petty Cash`}</span>
            </Modal.Header>
            <Modal.Body></Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-center">
                <ButtonCancel
                  className="mr-2"
                  disabled={formik.isSubmitting}
                  onClick={() => dismissModal(formik.resetForm)}
                />

                {modal.type === "CREATE" && (
                  <ButtonCreate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}

                {modal.type === "UPDATE" && (
                  <ButtonUpdate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}>
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
          />
        </Formik>
      )}
    </CRUDLayout>
  )
}

export default RealisasiPettyCashList
