import Axios from "axios"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card, Col, Modal, Row } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"
import * as Yup from "yup"
import { FakturPenjualanApi } from "../../../api"
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  TextArea
} from "../../../components"
import {
  DateConvert,
  DecimalConvert,
  GuestMiddleware,
  RupiahConvert
} from "../../../utilities"

const DetailFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const { no_faktur, tab = "" } = location.state
  const [isPageLoading, setIsPageLoading] = useState(true)

  const [dataListFP, setDataListFP] = useState([])
  const [dataFP, setDataFP] = useState({})
  const [dataApproval, setDataApproval] = useState([])
  const [approveStatus, setApproveStatus] = useState("V")
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: ""
  })

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle"
  }

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    // GET SINGLE DETAIL
    Axios.all([FakturPenjualanApi.getSingle({ no_faktur })])
      .then(
        Axios.spread((data) => {
          const behavior = data.data.data.behavior
            ? data.data.data.behavior
            : "V"
          const { approval, detail } = data.data.data
          setDataFP(detail)
          setDataApproval(approval ?? [])
          setDataListFP(detail.detail)
          setApproveStatus(behavior.toUpperCase())
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!"
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant
        })
      }
    }
  }

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate)
      return DateConvert(date).custom
    }
    return "-"
  }

  const InfoSection = () => {
    const InfoItem = ({ title, value, isPaddingTop }) => (
      <tr>
        <td
          style={{
            verticalAlign: "top",
            paddingTop: isPaddingTop ? "10px" : "0"
          }}>
          {title}
        </td>
        <td className="pl-4 pr-2" style={{ verticalAlign: "top" }}>
          :
        </td>
        <td>
          <span className="text-align-justify">{value}</span>
        </td>
      </tr>
    )

    return (
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                isPaddingTop
                title="Tgl. Sales Order"
                value={
                  dataFP?.tgl_sales_order
                    ? DateConvert(new Date(dataFP?.tgl_sales_order)).detail
                    : "-"
                }
              />
              <InfoItem
                title="No. Sales Order"
                value={dataFP?.no_sales_order ?? "-"}
              />
              <InfoItem title="Sales" value={dataFP.nama_sales ?? "-"} />
              <InfoItem title="Customer" value={dataFP?.nama_customer ?? "-"} />
              <InfoItem title="Proyek" value={dataFP?.nama_proyek ?? "-"} />
              <InfoItem
                title="Unit Produksi"
                value={dataFP?.nama_unit_produksi ?? "-"}
              />
              <InfoItem
                title="Tgl. Faktur"
                value={
                  dataFP?.tgl_faktur
                    ? DateConvert(new Date(dataFP?.tgl_faktur)).detail
                    : "-"
                }
              />
              <InfoItem title="No. Faktur" value={dataFP?.no_faktur ?? "-"} />
              {/* <InfoItem title="Alamat Pengiriman" value={dataFP?.alamat_pengiriman ?? "-"} />
              <InfoItem title="Catatan" value={dataFP?.catatan ?? "-"} /> */}
            </tbody>
          </table>
        </Col>
      </Row>
    )
  }

  const TableFP = ({ dataFP, dataListFP }) => {
    // GET TOTAL ORDER
    const getTotal = () =>
      dataListFP?.reduce((sum, jumlah) => sum + parseFloat(jumlah.jumlah), 0)

    // GET TOTAL DISKON
    const getJumlahDiskon = () => {
      let total = getTotal()
      return (total * dataFP.diskon) / 100
    }

    // GET TOTAL SETELAH DISKON
    const getTotalSetelahDiskon = () => {
      let total = getTotal()
      let jmlDiskon = getJumlahDiskon()
      return total - jmlDiskon
    }

    // GET TOTAL PPN
    const getJumlahPpn = () => {
      let totalSetelahDiskon = getTotalSetelahDiskon()
      return (totalSetelahDiskon * dataFP.ppn) / 100
    }

    // GET TOTAL SETELAH PPN
    const getTotalSetelahPpn = () => {
      let totalSetelahDiskon = parseFloat(getTotalSetelahDiskon())
      let jumlahPpn = parseFloat(getJumlahPpn())
      return totalSetelahDiskon + jumlahPpn
    }

    return (
      <>
        <div className="p-2 mt-3">
          <b>List Item Detail Faktur</b>
        </div>

        <div>
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center bg-light">
              <tr key="head1">
                <th
                  className="align-middle"
                  style={{ ...tableStyling, width: "30px" }}>
                  No.
                </th>
                <th className="align-middle" style={tableStyling}>
                  Tgl. Surat Jalan
                </th>
                <th className="align-middle" style={tableStyling}>
                  No. Surat Jalan
                </th>
                <th className="align-middle" style={tableStyling}>
                  No. Delivery Order
                </th>
                <th
                  className="align-middle"
                  style={{ ...tableStyling, width: "300px" }}>
                  Item Barang
                </th>
                <th
                  className="align-middle"
                  style={{ ...tableStyling, width: "100px" }}>
                  Qty. Surat Jalan
                </th>
                <th className="align-middle" style={tableStyling}>
                  Harga Satuan Jual
                </th>
                <th className="align-middle" style={tableStyling}>
                  Jumlah
                </th>
              </tr>
            </thead>
            <tbody>
              {dataListFP && dataListFP.length > 0 ? (
                dataListFP.map((val, index) => {
                  val.sub_total =
                    parseFloat(val.qty_item) * parseFloat(val.harga_satuan_jual)
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td style={tableStyling} className="px-2">
                        {val.tgl_surat_jalan
                          ? DateConvert(new Date(val.tgl_surat_jalan)).custom
                          : "-"}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.no_surat_jalan ? val.no_surat_jalan : "-"}
                      </td>
                      <td style={tableStyling} className="px-2">
                        {val.no_delivery_order ? val.no_delivery_order : "-"}
                      </td>
                      <td style={tableStyling}>
                        {val.nama_item && val.kode_item
                          ? `(${val.kode_item}) ${val.nama_item}`
                          : "-"}
                      </td>
                      <td style={tableStyling} className="px-2 text-right">
                        {val.qty && val.nama_satuan
                          ? `${DecimalConvert(val.qty).getWithComa} ${
                              val.nama_satuan
                            }`
                          : "-"}
                      </td>
                      <td style={tableStyling} className="px-2 text-right">
                        {
                          RupiahConvert(
                            val.harga_satuan_jual
                              ? String(val.harga_satuan_jual)
                              : "-"
                          ).getWithComa
                        }
                      </td>
                      <td style={tableStyling} className="px-2 text-right">
                        {
                          RupiahConvert(
                            val.jumlah ? String(parseFloat(val.jumlah)) : "-"
                          ).getWithComa
                        }
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td className="text-center bg-light" colSpan={8}>
                    Tidak Ada Data
                  </td>
                </tr>
              )}
              {dataListFP && dataListFP.length > 0 ? (
                <>
                  <tr className="bg-light">
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Total :
                    </td>
                    <td
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getTotal()
                        ? RupiahConvert(String(getTotal())).getWithComa
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {`Diskon (${
                        DecimalConvert(dataFP.diskon).getWithComa
                      }%) : `}
                    </td>
                    <td
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getJumlahDiskon()
                        ? RupiahConvert(String(getJumlahDiskon())).getWithComa
                        : "-"}
                    </td>
                  </tr>

                  <tr className="bg-light">
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Total Setelah Diskon
                    </td>
                    <td
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getTotalSetelahDiskon()
                        ? RupiahConvert(String(getTotalSetelahDiskon()))
                            .getWithComa
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {`PPN (${DecimalConvert(dataFP.ppn).getWithComa}%) : `}
                    </td>
                    <td
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getJumlahPpn()
                        ? RupiahConvert(String(getJumlahPpn())).getWithComa
                        : "-"}
                    </td>
                  </tr>

                  <tr className="bg-light">
                    <td
                      colSpan={7}
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      Total Setelah PPN
                    </td>
                    <td
                      className="text-right"
                      style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {getTotalSetelahPpn()
                        ? RupiahConvert(String(getTotalSetelahPpn()))
                            .getWithComa
                        : "-"}
                    </td>
                  </tr>
                  {/* <tr>
                    <td colSpan={8} style={{ fontWeight: "bold", fontSize: "14px" }}>
                      <b>Catatan :</b>
                      <br />
                      <p className="text-justify">{dataFP.catatan}</p>
                    </td>
                  </tr> */}
                </>
              ) : (
                <div />
              )}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }>
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    )
  }

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty
  }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Faktur Penjualan</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER"
                    ? `PEMERIKSA ${val.approval_level}`
                    : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: (
                              <span className="text-danger">
                                Reject Faktur Penjualan
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Faktur Penjualan
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: (
                              <span className="text-danger">
                                Reject Faktur Penjualan
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REV",
                            title: (
                              <span className="text-warning">
                                Revise Faktur Penjualan
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Faktur Penjualan
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    )
  }

  useEffect(() => {
    setNavbarTitle("Approval Faktur Penjualan")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [])

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Faktur Penjualan</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}

          {!isPageLoading && (
            <TableFP dataFP={dataFP} dataListFP={dataListFP} />
          )}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan")
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataFP.no_faktur,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataFP?.baseline ?? 0
          }

          FakturPenjualanApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/faktur-penjualan", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${
                    modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                  } data berhasil!`
                }
              })
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: `Simpan approval gagal! (${
                  err?.response?.data?.message ?? ""
                })`
              })
            })
            .finally(() => setModalConfig({ show: false }))
        }}>
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty
        }) => (
          <>
            <GuestMiddleware>
              <FormCard
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                validateForm={validateForm}
                dirty={dirty}
              />
            </GuestMiddleware>
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  )
}

export default DetailFakturPenjualan
