import {
  ApprovalStatusButton,
  CRUDLayout,
  Pagination,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr
} from "components"
import {
  ButtonCreate,
  ButtonDetail,
  ButtonFilter,
  ButtonUpdate,
  DataStatus,
  InputSearch
} from "components2"
import { Formik } from "formik"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import { ButtonGroup } from "react-bootstrap"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { dateConvert, rupiahConvert, tableNumber } from "utilities2"
import { STATUS_APPROVAL } from "./__PenerimaPettyCashUtils__"
import PenerimaanPettyCashApi from "./__PenerimaanPettyCashApi__"
import { ModalFilter } from "./__PenerimaanPettyCashComps__"
import { GuestMiddleware } from "utilities"

export const PenerimaanPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory()
  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    per_page: 10,
    tgl_penerimaan_petty_cash_start: undefined,
    tgl_penerimaan_petty_cash_end: undefined,
    tgl_mutasi_petty_cash_start: undefined,
    tgl_mutasi_petty_cash_end: undefined,
    tgl_awal_petty_cash_start: undefined,
    tgl_awal_petty_cash_end: undefined,
    tgl_akhir_petty_cash_start: undefined,
    tgl_akhir_petty_cash_end: undefined,
    id_unit_produksi: undefined,
    status_approval: undefined,
    active: false
  })

  const [modalFilter, setModalFilter] = useState(false)

  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10
  })

  const getPenerimaPettyCash = useQuery(
    ["penerimaan_petty_cash", "list", pagination, filter],
    () => PenerimaanPettyCashApi.getList({ ...pagination, ...filter })
  )

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined

    setPagination({
      ...pagination,
      q: searchKey,
      page: "1"
    })
  }

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter)

  const onCreateButtonClickHandler = () =>
    history.push(
      "/human-resource/transaksi/penerimaan-petty-cash/mutasi-petty-cash"
    )

  const onDetailButtonClickHandler = (id) =>
    history.push(`/human-resource/transaksi/penerimaan-petty-cash/detail/${id}`)

  const onUpdateButtonClickHandler = (id) =>
    history.push(`/human-resource/transaksi/penerimaan-petty-cash/ubah/${id}`)

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 })

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value })

  const formInitialValuesFilter = {
    tgl_penerimaan_petty_cash_start: filter?.tgl_penerimaan_petty_cash_start,
    tgl_penerimaan_petty_cash_end: filter?.tgl_penerimaan_petty_cash_end,
    tgl_mutasi_petty_cash_start: filter?.tgl_mutasi_petty_cash_start,
    tgl_awal_petty_cash_end: filter?.tgl_awal_petty_cash_end,
    tgl_akhir_petty_cash_start: filter?.tgl_akhir_petty_cash_start,
    tgl_akhir_petty_cash_end: filter?.tgl_akhir_petty_cash_start,
    id_unit_produksi: filter?.id_unit_produksi,
    status_approval: filter?.status_approval
  }

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1
    })
    onFilterButtonClickkHandler()
  }

  useEffect(() => setNavbarTitle("Penerimaan Petty Cash"), [])

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch
              onChange={debounce(searchHandler, 1500)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <GuestMiddleware>
            <ButtonCreate size="md" icon onClick={onCreateButtonClickHandler} />
          </GuestMiddleware>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPenerimaPettyCash.isLoading || getPenerimaPettyCash.isError ? (
        <DataStatus
          loading={getPenerimaPettyCash.isLoading}
          text={
            getPenerimaPettyCash.isLoading
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Penerimaan Petty Cash
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed rowSpan={2}>No.</ThFixed>
                <ThFixed rowSpan={2}>Aksi</ThFixed>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Informasi Penerimaan <br /> Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Informasi Bukti Mutasi
                </Th>
                <Th rowSpan={2} style={{ width: 120 }} className="p-1">
                  Unit Produksi
                </Th>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Nominal Penerimaan <br /> Petty Cash
                </Th>
                <Th colSpan={2} style={{ minWidth: 180 }} className="p-1">
                  Masa Berlaku Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 80 }} className="p-1">
                  Sisa Masa Berlaku Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 10 }} className="p-1">
                  Status Approval
                </Th>
              </Tr>
              <Tr>
                <Th style={{ width: 85 }} className="p-1">
                  Tgl. Awal Petty Cash
                </Th>
                <Th style={{ width: 85 }} className="p-1">
                  Tgl. Akhir Petty Cash
                </Th>
              </Tr>
            </THead>
            <TBody>
              {getPenerimaPettyCash?.data?.data?.length > 0 ? (
                getPenerimaPettyCash?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index
                      })}
                    </TdFixed>
                    <TdFixed className="text-center">
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          variant="primary"
                          onClick={() =>
                            onDetailButtonClickHandler(
                              e.id_penerimaan_petty_cash
                            )
                          }
                        />
                        <GuestMiddleware>
                          {e.status_approval === "REV" && (
                            <ButtonUpdate
                              icon
                              noText
                              variant="success"
                              onClick={() =>
                                onUpdateButtonClickHandler(
                                  e.id_penerimaan_petty_cash
                                )
                              }
                            />
                          )}
                        </GuestMiddleware>
                      </ButtonGroup>
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_penerimaan_petty_cash)
                        )}
                      </div>
                      <div>{e.no_penerimaan_petty_cash}</div>
                    </Td>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_mutasi_petty_cash)
                        )}
                      </div>
                      <div>{e.no_bukti_mutasi}</div>
                    </Td>
                    <Td>{e.nama_unit_produksi}</Td>
                    <Td className="text-right">
                      {rupiahConvert().getWithComa(
                        e.nominal_penerimaan_petty_cash
                      )}
                    </Td>
                    <Td className="text-center">
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_awal_petty_cash)
                      )}
                    </Td>
                    <Td className="text-center">
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_akhir_petty_cash)
                      )}
                    </Td>
                    <Td className="text-center">{e?.sisa_durasi ?? 0} hari</Td>
                    <Td className="text-center">
                      <ApprovalStatusButton
                        variant={
                          STATUS_APPROVAL[e.status_approval ?? "PEN"]?.variant
                        }>
                        {STATUS_APPROVAL[e.status_approval ?? "PEN"]?.label}
                      </ApprovalStatusButton>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.per_page}
            dataNumber={
              pagination?.page * pagination?.per_page - pagination?.per_page + 1
            }
            dataPage={
              pagination?.dataCount < pagination?.per_page
                ? pagination?.dataCount
                : pagination?.page * pagination?.per_page
            }
            dataCount={getPenerimaPettyCash?.data?.data_count}
            currentPage={pagination?.page}
            totalPage={getPenerimaPettyCash?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}>
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
          />
        </Formik>
      )}
    </CRUDLayout>
  )
}
