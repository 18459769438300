import { PenawaranApi } from "api"
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  TextArea,
  TextEditor
} from "components"
import { ContentState, convertFromHTML, EditorState } from "draft-js"
import { Formik, useFormik } from "formik"
import { memo, useEffect, useState } from "react"
import { Card, Col, Modal, Row } from "react-bootstrap"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { DateConvert, GuestMiddleware } from "utilities"
import * as Yup from "yup"
import { FormPenawaran, InfoSection, TableBarangJadi } from "./Section"

const DetailPenawaran = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const { id_penawaran } = useParams()
  const { no_penawaran, tab } = location.state || {}

  const ContentDetailPenawaran = memo(() => {
    const [dataPenawaran, setDataPenawaran] = useState({})
    const [approveStatus, setApproveStatus] = useState("V")
    const [dataApproval, setDataApproval] = useState([])
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isFetchingFailed, setIsFetchingFailed] = useState(false)
    const [dataBarangJadi, setDataBarangJadi] = useState([])
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: ""
    })
    const [modalConfig, setModalConfig] = useState({
      show: false,
      type: "",
      title: ""
    })
    const [textEditorState, setTextEditorState] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            "<p>Dengan hormat,<br />Bersama ini kami sampaikan penawaran harga sebagai berikut :</p>"
          )
        )
      )
    })
    const [textEditorStatePenutup, setTextEditorStatePenutup] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(`<p>Catatan :<br />`))
      )
    })

    const getInitialData = () => {
      setIsPageLoading(true)

      PenawaranApi.getSinglePenawaran({ no_penawaran })
        .then((pnw) => {
          const behavior = String(pnw?.data?.data?.behavior).toUpperCase()
          const mapDataBarangJadi =
            pnw?.data?.data?.detail?.detail_barang_jadi?.map((val) =>
              Object({
                ...val,
                satuan: val.nama_satuan_jual,
                qty: val.qty_rab ?? 0,
                harga_satuan: val.unit_cost ?? 0,
                rounded: val.rounded ?? 0
              })
            )

          setDataPenawaran(pnw?.data?.data?.detail ?? {})
          setDataBarangJadi(mapDataBarangJadi ?? [])
          setApproveStatus(behavior ?? "V")
          setDataApproval(pnw?.data?.data?.approval ?? [])
        })
        .catch((err) => {
          setIsFetchingFailed(true)
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!"
          })
        })
        .finally(() => setIsPageLoading(false))
    }

    const formInitialValues = {
      id_penawaran: id_penawaran,
      id_rae: dataPenawaran?.id_rae,
      tgl_rab: dataPenawaran?.tgl_rab ?? "",
      no_rab: dataPenawaran?.no_rab ?? "",
      tgl_penawaran: dataPenawaran?.tgl_penawaran ?? "",
      no_penawaran: dataPenawaran?.no_penawaran ?? "",
      diskon: parseFloat(dataPenawaran?.diskon ?? 0),
      ppn: parseFloat(dataPenawaran?.ppn ?? 0)
    }
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues
    })

    useEffect(() => {
      setNavbarTitle("Penawaran")
      getInitialData()
    }, [])

    useEffect(() => {
      setTextEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(dataPenawaran?.teks_pembuka ?? "<p></p>")
          )
        )
      )
      setTextEditorStatePenutup(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(dataPenawaran?.teks_penutup ?? "<p></p>")
          )
        )
      )
      return () => {}
    }, [dataPenawaran])

    const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
      const ModalContent = () => (
        <>
          <Modal.Body className="text-center">
            <p>
              <h6>
                <b>
                  {modalConfig.title === "APP"
                    ? approveStatus === "A"
                      ? "Approve"
                      : "Verify"
                    : modalConfig.title}{" "}
                  dengan catatan :
                </b>
              </h6>
              <span>{values.catatan}</span>
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ActionButton
              variant="outline-secondary"
              text="Batal"
              className="px-4"
              onClick={() =>
                setModalConfig({
                  ...modalConfig,
                  show: false
                })
              }
            />
            <ActionButton
              text={
                modalConfig.type === "APP"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.type === "REJ"
                  ? "Reject"
                  : "Revise"
              }
              variant={
                modalConfig.type === "APP"
                  ? "success"
                  : modalConfig.type === "REV"
                  ? "warning"
                  : "danger"
              }
              className="m-1 px-3 text-white"
              onClick={handleSubmit}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </>
      )

      return (
        <Modal
          show={modalConfig.show}
          onHide={() =>
            setModalConfig({
              ...modalConfig,
              show: false
            })
          }>
          <Modal.Header closeButton className="py-2 d-flex align-items-center">
            <Modal.Title>
              <small>{modalConfig.title}</small>
            </Modal.Title>
          </Modal.Header>
          <ModalContent />
        </Modal>
      )
    }

    const FormCard = ({
      values,
      handleChange,
      dirty,
      validateForm,
      errors
    }) => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="col-md-4 mb-4">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      )

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Approval Penawaran</b>
          </Card.Header>
          <Card.Body>
            <div className="d-flex align-content-between flex-wrap">
              {dataApproval.map((val, index) => (
                <InfoItem
                  key={index}
                  title1={
                    val.status_approval == "VER"
                      ? `PEMERIKSA ${val.approval_level}`
                      : "PENGESAH"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2={"Catatan"}
                  value2={val.catatan ?? "-"}
                />
              ))}
            </div>
            <hr />
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                <ActionButton
                  text="Reject"
                  variant="danger"
                  className="m-1"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: "REJ",
                        title: (
                          <span className="text-danger">Reject Penawaran</span>
                        )
                      })
                    }
                    validateForm()
                  }}
                />
                {approveStatus !== "A" && (
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: (
                            <span className="text-warning">
                              Revise Penawaran
                            </span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                )}
                <ActionButton
                  text={approveStatus === "A" ? "Approve" : "Verify"}
                  variant="success"
                  className="m-1"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: "APP",
                        title: (
                          <span className="text-success">
                            Approve Penawaran
                          </span>
                        )
                      })
                    }
                    validateForm()
                  }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      )
    }

    const CatatanApproval = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      )

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Penawaran</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataPenawaran?.stakeholder?.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== "PEN" && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" ||
                          val.status_approval === "REV"
                            ? `Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                            : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                    </Col>
                  )
              )}
            </Row>
          </Card.Body>
        </Card>
      )
    }

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Detail Data Penawaran</b>
            <BackButton onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() => setAlertConfig({ ...alertConfig, show: false })}
            />
            {isPageLoading ? (
              <DataStatus loading={true} text="Memuat data . . ." />
            ) : isFetchingFailed ? (
              <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
            ) : (
              <>
                <InfoSection data={dataPenawaran} />
                <hr />
                <FormPenawaran formik={formik} type="DETAIL" />
                <TextEditor
                  readOnly
                  label="Teks Pembuka"
                  placeholder="Tuliskan teks pembuka"
                  editorState={textEditorState}
                />
                <TableBarangJadi
                  dataBarangJadi={dataBarangJadi}
                  setDataBarangJadi={setDataBarangJadi}
                  diskon={formik.values.diskon}
                  ppn={formik.values.ppn}
                />
                <TextEditor
                  readOnly
                  label="Teks Penutup"
                  placeholder="Tuliskan teks penutup"
                  editorState={textEditorStatePenutup}
                />
              </>
            )}
          </Card.Body>
        </Card>

        {!isPageLoading && tab !== "history" && (
          <Formik
            initialValues={{ catatan: "" }}
            validationSchema={Yup.object().shape({
              catatan: Yup.string().required("Masukan Catatan")
            })}
            onSubmit={(values) => {
              const finalValues = {
                ...values,
                no_transaksi: dataPenawaran.no_penawaran,
                status_approval: modalConfig.type,
                tgl_approval: DateConvert(new Date()).default,
                approval_baseline: dataPenawaran.baseline
              }

              PenawaranApi.approve(finalValues)
                .then((res) => {
                  history.push("/human-resource/approval/penawaran", {
                    alert: {
                      show: true,
                      variant: "primary",
                      text: `${
                        modalConfig.type == "APP"
                          ? "Approve"
                          : modalConfig.type == "REV"
                          ? "Revise"
                          : "Reject"
                      } data berhasil!`
                    }
                  })
                })
                .catch((err) => {
                  setAlertConfig({
                    variant: "danger",
                    text: `Ubah data gagal! (${err.response.data.message})`
                  })
                })
                .finally(() => setModalConfig({ show: false }))
            }}>
            {({
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              validateForm,
              dirty,
              errors,
              touched
            }) => (
              <>
                <GuestMiddleware>
                  <FormCard
                    values={values}
                    handleChange={handleChange}
                    dirty={dirty}
                    validateForm={validateForm}
                    errors={errors}
                    touched={touched}
                  />
                </GuestMiddleware>
                <ModalSection
                  values={values}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
          </Formik>
        )}

        {!isPageLoading && tab === "history" && <CatatanApproval />}
      </>
    )
  })

  return <ContentDetailPenawaran />
}

export default DetailPenawaran
