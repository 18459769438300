import { ApprovalRAPApi } from "api"
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  TextArea
} from "components"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card, Col, Modal, Row } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"
import { DateConvert, GuestMiddleware, RupiahConvert } from "utilities"
import * as Yup from "yup"
import { TableBarangJadi } from "./Table"

const DetailRAP = ({ setNavbarTitle }) => {
  const history = useHistory()
  const { state } = useLocation()
  const no_rap = state.no_rap
  const tab = state?.tab ?? ""
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [dataRAP, setDataRAP] = useState({})
  const [approveStatus, setApproveStatus] = useState("v")
  const [dataApproval, setDataApproval] = useState([])
  const [dataSelectJenisBarangJadi, setDataSelectJenisBarangJadi] = useState([])
  const [dataSelectAnalisaBarangJadi, setDataSelectAnalisaBarangJadi] =
    useState([])
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: ""
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    ApprovalRAPApi.getSingle({ no_rap: no_rap })
      .then((rap) => {
        const checkRAP = rap.data.data.detail ?? {}
        const checkApp = rap.data.data.approval ?? []
        const checkStatus = rap.data.data.behavior ?? "v"

        setDataRAP(checkRAP)
        setDataApproval(checkApp)
        setApproveStatus(checkStatus)
      })
      .catch(() => {
        setIsFetchingFailed(true)
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!"
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const whenNoRAP = () => {
    setIsPageLoading(false)
    setIsFetchingFailed(true)
  }

  useEffect(() => {
    setNavbarTitle("Rencana Anggaran Produksi (RAP)")
    getInitialData()
    no_rap ? getInitialData() : whenNoRAP()

    return () => {
      setIsPageLoading(false)
    }
  }, [])

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    )

    return (
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Peluang"
                value={
                  dataRAP.tgl_peluang
                    ? DateConvert(new Date(dataRAP.tgl_peluang)).detail
                    : "-"
                }
              />
              <InfoItem
                title="No. Peluang"
                value={dataRAP.no_peluang ? dataRAP.no_peluang : "-"}
              />
              <InfoItem
                title="Tgl. RAP"
                value={
                  dataRAP.tgl_rap
                    ? DateConvert(new Date(dataRAP.tgl_rap)).detail
                    : "-"
                }
              />
              <InfoItem
                title="No. RAP"
                value={dataRAP.no_rap ? dataRAP.no_rap : "-"}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Customer"
                value={dataRAP.nama_customer ? dataRAP.nama_customer : "-"}
              />
              <InfoItem title="ATT" value={dataRAP.att ? dataRAP.att : "-"} />
              <InfoItem
                title="Tanggal Selesai"
                value={
                  dataRAP.tgl_selesai
                    ? DateConvert(new Date(dataRAP.tgl_selesai)).detail
                    : "-"
                }
              />
              <InfoItem
                title="Peringkat Peluang"
                value={
                  dataRAP.nama_peringkat_peluang
                    ? dataRAP.nama_peringkat_peluang
                    : "-"
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    )
  }

  const TableSection = () => {
    const [dataBarangJadi, setDataBarangJadi] = useState([])
    const [dataLabourCost, setDataLabourCost] = useState([])
    const [dataMachineProcess, setDataMachineProcess] = useState([])

    useEffect(() => {
      setDataBarangJadi(
        dataRAP.detail_barang_jadi ? dataRAP.detail_barang_jadi : []
      )
      setDataLabourCost(dataRAP.detail_upah ? dataRAP.detail_upah : [])
      setDataMachineProcess(
        dataRAP.detail_alat_mesin ? dataRAP.detail_alat_mesin : []
      )
    }, [])

    const GrandTotalSection = () => {
      const getGrandTotal = () => {
        const totalBarangJadi = dataBarangJadi.reduce(
          (prevValue, currentValue) => {
            const checkQty = currentValue.qty_rap
              ? parseFloat(currentValue.qty_rap)
              : "0"
            const checkSatuan = currentValue.harga_satuan_rap
              ? parseInt(currentValue.harga_satuan_rap)
              : "0"
            const subTotal = parseInt(checkQty * checkSatuan)
            const total = parseInt(prevValue + subTotal)

            return total
          },
          0
        )

        const grandTotal = parseInt(totalBarangJadi)

        return RupiahConvert(grandTotal.toString()).detail
      }

      return (
        <div className="mt-4 p-2 px-3 text-right border">
          <b>Grand Total : </b>
          <b className="pl-3">{getGrandTotal()}</b>
        </div>
      )
    }

    return (
      <>
        <TableBarangJadi
          dataBarangJadi={dataBarangJadi}
          dataSelectJenisBarangJadi={dataSelectJenisBarangJadi}
          dataSelectAnalisaBarangJadi={dataSelectAnalisaBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          setDataSelectJenisBarangJadi={setDataSelectJenisBarangJadi}
          setDataSelectAnalisaBarangJadi={setDataSelectAnalisaBarangJadi}
        />
        <GrandTotalSection />
      </>
    )
  }

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }>
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    )
  }

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty
  }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval RAP</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER"
                    ? `PEMERIKSA ${val.approval_level}`
                    : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject RAP</span>
                        })
                      }
                      validateForm()
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: (
                            <span className="text-success">Approve RAP</span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject RAP</span>
                        })
                      }
                      validateForm()
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: (
                            <span className="text-warning">Revise RAP</span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: (
                            <span className="text-success">Approve RAP</span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    )
  }
  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan RAP</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataRAP?.stakeholder?.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== "PEN" && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" ||
                        val.status_approval === "REV"
                          ? `Pemeriksa ${
                              val.approval_level !== "0"
                                ? val.approval_level
                                : ""
                            }`
                          : val.status_approval === "APP"
                          ? "Pengesah"
                          : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    )
  }

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data RAP</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableSection />
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && tab !== "history" && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan Catatan")
          })}
          onSubmit={(values) => {
            const finalValues = {
              ...values,
              no_transaksi: dataRAP.no_rap,
              status_approval: modalConfig.type,
              tgl_approval: DateConvert(new Date()).default,
              approval_baseline: dataRAP.baseline
            }

            ApprovalRAPApi.save(finalValues)
              .then((res) => {
                history.push("/human-resource/approval/rap", {
                  alert: {
                    show: true,
                    variant: "primary",
                    text: `${
                      modalConfig.type == "APP"
                        ? "Approve"
                        : modalConfig.type == "REV"
                        ? "Revise"
                        : "Reject"
                    } data berhasil!`
                  }
                })
              })
              .catch((err) => {
                setAlertConfig({
                  show: true,
                  variant: "danger",
                  text: `Simpan approval gagal!`
                })
              })
              .finally(() => setModalConfig({ show: false }))
          }}>
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            validateForm,
            dirty
          }) => (
            <>
              <GuestMiddleware>
                <FormCard
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  validateForm={validateForm}
                  dirty={dirty}
                />
              </GuestMiddleware>
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
      {!isFetchingFailed && !isPageLoading && tab === "history" && (
        <CatatanApproval />
      )}
    </>
  )
}

export default DetailRAP
