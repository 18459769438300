import { useEffect, useState } from "react"
import { Card, Nav } from "react-bootstrap"
import { ApprovalProgram, HistoryApprovalProgram } from "./Tabs"

const MainApprovalProgram = ({ setNavbarTitle }) => {
  useEffect(() => {
    setNavbarTitle("APPROVAL PROGRAM")
    return () => {}
  }, [])
  const [tabs, setTabs] = useState("approval") //State untuk menampung tabs yang aktif

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault()
    setTabs(newPage)
  }

  const TabsNav = ({ tab, label }) => (
    <Nav.Item>
      <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
        {label}
      </Nav.Link>
    </Nav.Item>
  )

  return (
    <Card>
      <Card.Header>
        <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
          <TabsNav tab="approval" label="Approval" />
          <TabsNav tab="history" label="History" />
        </Nav>
      </Card.Header>
      <Card.Body>
        {tabs === "approval" ? <ApprovalProgram /> : <HistoryApprovalProgram />}
      </Card.Body>
    </Card>
  )
}

export default MainApprovalProgram
