import Axios from "axios"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card, Col, Modal, Row } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"
import * as Yup from "yup"
import { PenerimaanBarangApi } from "../../../api"
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  TextArea
} from "../../../components"
import { DateConvert, GuestMiddleware, RupiahConvert } from "../../../utilities"

const DetailPenerimaanBarang = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()

  const { no_penerimaan_barang = "", tab = "" } = location.state
  const [isPageLoading, setIsPageLoading] = useState(true)

  const [dataPenerimaanBarang, setDataPenerimaanBarang] = useState({})
  const [dataApproval, setDataApproval] = useState([])
  const [approveStatus, setApproveStatus] = useState("V")

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: ""
  })

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    // GET SINGLE DETAIL Penerimaan Barang
    Axios.all([
      PenerimaanBarangApi.getSingle({
        no_penerimaan_barang: no_penerimaan_barang
      })
    ])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data
          const behavior = data.data.data.behavior
            ? data.data.data.behavior
            : "V"
          setDataPenerimaanBarang(detail)
          setDataApproval(approval ?? [])
          setApproveStatus(behavior.toUpperCase())
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!"
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant
        })
      }
    }
  }

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value)
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber))

    return new Intl.NumberFormat("id-ID").format(checkConvert)
  }

  console.log(dataPenerimaanBarang)

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate)
      return DateConvert(date).detail
    }
    return "-"
  }

  const InfoSection = () => {
    const InfoItem = ({ title, value, style }) => (
      <tr style={{ ...style }}>
        <td>{title}</td>
        <td className="pl-4 pr-2">{!title == "" ? ":" : ""}</td>
        <td>{value}</td>
      </tr>
    )

    const harga =
      parseFloat(dataPenerimaanBarang.harga_kesepakatan ?? 0) +
      parseFloat(dataPenerimaanBarang.harga_kesepakatan_biaya_angkut ?? 0)

    return (
      <>
        <Row>
          <Col>
            <table style={{ fontSize: "14px" }}>
              <tbody>
                <InfoItem
                  title="Tgl. Purchase Order"
                  value={
                    dataPenerimaanBarang?.tgl_purchase_order
                      ? getConvertedDate(
                          dataPenerimaanBarang?.tgl_purchase_order
                        )
                      : "-"
                  }
                />
                <InfoItem
                  title="Vendor"
                  value={dataPenerimaanBarang?.nama_vendor ?? "-"}
                />
                <InfoItem
                  title="Proyek"
                  value={dataPenerimaanBarang?.nama_proyek ?? "-"}
                />
                <InfoItem
                  title="Qty. Purchase Order"
                  value={decimalConvert(dataPenerimaanBarang.qty_order ?? 0)}
                />

                <div className="mt-3" />

                <InfoItem
                  title="Tgl. Penerimaan Barang"
                  value={
                    dataPenerimaanBarang?.tgl_penerimaan_barang
                      ? getConvertedDate(
                          dataPenerimaanBarang?.tgl_penerimaan_barang
                        )
                      : "-"
                  }
                />
                <InfoItem
                  title="No. Surat Jalan"
                  value={dataPenerimaanBarang?.no_surat_jalan ?? "-"}
                />
                <InfoItem
                  title="Gudang Penerimaaan"
                  value={dataPenerimaanBarang?.nama_gudang ?? "-"}
                />

                <div className="mt-3" />

                <InfoItem
                  title="Qty. Penerimaan Dalam Satuan Beli"
                  value={`${decimalConvert(
                    dataPenerimaanBarang?.qty_beli ?? 0
                  )} ${dataPenerimaanBarang?.nama_satuan_beli ?? ""}`}
                />
                <InfoItem
                  title="Harga Barang Per Satuan Beli"
                  value={
                    dataPenerimaanBarang.harga_kesepakatan
                      ? RupiahConvert(parseFloat(harga).toString()).getWithComa
                      : 0
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: "14px" }}>
              <tbody>
                <InfoItem
                  title="No. Purchase Order"
                  value={dataPenerimaanBarang?.no_purchase_order ?? "-"}
                />
                <InfoItem title="Item" value={dataPenerimaanBarang.nama_item} />

                <div className="mt-2" />

                <InfoItem
                  title="No. Penerimaan Barang"
                  value={dataPenerimaanBarang?.no_penerimaan_barang ?? "-"}
                />
                <InfoItem
                  title="Petugas Penerimaan"
                  value={dataPenerimaanBarang?.nama_karyawan ?? "-"}
                />
                <InfoItem
                  title="Keterangan PO"
                  value={dataPenerimaanBarang?.keterangan_po ?? "-"}
                />

                <div className="mt-5" />

                <InfoItem
                  title="Qty. Penerimaan Dalam Satuan Pakai"
                  value={`${decimalConvert(
                    dataPenerimaanBarang?.qty_pakai ?? 0
                  )} ${dataPenerimaanBarang?.nama_satuan_pakai ?? ""}`}
                />

                <InfoItem
                  title="Harga Barang Per Satuan Pakai"
                  value={
                    dataPenerimaanBarang?.harga_kesepakatan &&
                    dataPenerimaanBarang?.nilai_konversi
                      ? `Rp${(
                          parseInt(harga) /
                          parseInt(dataPenerimaanBarang.nilai_konversi)
                        )
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")}`
                      : "Rp0"
                  }
                />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />
        <div className="row justify-content-center mt-4">
          {/* <Col lg="10"> */}
          <div className="text-center">
            <img
              src={dataPenerimaanBarang.foto_surat_jalan}
              className="img-fluid"
              width="400"
            />
          </div>
          {/* </Col> */}
        </div>
      </>
    )
  }

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )

    console.log("test", modalConfig.type)

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }>
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    )
  }

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty
  }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Penerimaan Barang</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER"
                    ? `PEMERIKSA ${val.approval_level}`
                    : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: (
                              <span className="text-danger">
                                Reject Penerimaan Barang
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Penerimaan Barang
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: (
                              <span className="text-danger">
                                Reject Penerimaan Barang
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REV",
                            title: (
                              <span className="text-warning">
                                Revise Penerimaan Barang
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Penerimaan Barang
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    )
  }

  useEffect(() => {
    setNavbarTitle("Detail Data Penerimaan Barang")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [])

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Penerimaan Barang</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoSection />
              <hr />
            </>
          )}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan")
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataPenerimaanBarang.no_penerimaan_barang,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: 0
          }

          console.log("simpan", finalValues)

          PenerimaanBarangApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/penerimaan-barang", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${
                    modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                  } data berhasil!`
                }
              })
            })
            .catch((err) => {
              setAlertConfig({
                variant: "danger",
                text: `Ubah data gagal! (${err.response.data.message})`
              })
            })
            .finally(() => setModalConfig({ show: false }))
        }}>
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty
        }) => (
          <>
            <GuestMiddleware>
              <FormCard
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                validateForm={validateForm}
                dirty={dirty}
              />
            </GuestMiddleware>
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  )
}

export default DetailPenerimaanBarang
