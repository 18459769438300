import { PurchaseRequestApi } from "api"
import Axios from "axios"
import {
  ActionButton,
  BackButton,
  CRUDLayout,
  TBody,
  THead,
  Td,
  TdFixed,
  TextArea,
  TextEditor,
  Th,
  ThFixed,
  Tr
} from "components"
import { ContentState, EditorState, convertFromHTML } from "draft-js"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card, Col, Modal, Row, Spinner } from "react-bootstrap"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { DateConvert, GuestMiddleware } from "utilities"
import * as Yup from "yup"

const DetailPurchaseRequest = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()

  const { id } = useParams()
  const { no_purchase_request, tab } = location.state
  const [isPageLoading, setIsPageLoading] = useState(true)

  const [dataListPR, setDataListPR] = useState([])
  const [dataPR, setDataPR] = useState({})
  const [dataApproval, setDataApproval] = useState([])
  // console.log(dataApproval)
  console.log(dataPR)
  const [approveStatus, setApproveStatus] = useState("V")

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: ""
  })

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle"
  }

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    // GET SINGLE DETAIL PURCHASE REQUEST
    Axios.all([
      PurchaseRequestApi.getSingle({
        no_purchase_request: no_purchase_request
      })
    ])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data
          const behavior = data.data.data.behavior
            ? data.data.data.behavior
            : "V"
          setDataPR(detail)
          setDataApproval(approval ?? [])
          setDataListPR(detail.detail)
          setApproveStatus(behavior.toUpperCase())
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!"
        })
      })
      .finally(() => setIsPageLoading(false))
  }
  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value)
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber))

    return new Intl.NumberFormat("id-ID").format(checkConvert)
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant
        })
      }
    }
  }

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate)
      return DateConvert(date).custom
    }
    return "-"
  }

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }>
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    )
  }

  useEffect(() => {
    setNavbarTitle("Detail Data Purchase Request")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [])

  // CONTENT
  const DetailPR = () => {
    const InfoItemDetail = ({ label, text }) => (
      <div className="d-flex flex-column mb-2">
        <small style={{ fontSize: 12 }}>{label}</small>
        <b style={{ fontSize: 14 }}>
          {text.charAt(0).toUpperCase() + text.slice(1)}
        </b>
      </div>
    )
    return (
      <>
        <div>
          <span style={{ fontSize: "14px" }}>
            <b>Detail Data Purchase Request</b>
          </span>
        </div>
        <Card className="mb-4">
          <Card.Body>
            <Row>
              {isPageLoading === false ? (
                <>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Tgl. Purchase Request"
                      text={
                        dataPR?.tgl_purchase_request
                          ? DateConvert(new Date(dataPR?.tgl_purchase_request))
                              .detail
                          : "-"
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="No Purchase Request"
                      text={dataPR?.no_purchase_request ?? "-"}
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Tgl. Pemakaian"
                      text={
                        dataPR?.tgl_pemakaian
                          ? DateConvert(new Date(dataPR?.tgl_pemakaian)).detail
                          : "-"
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Keperluan"
                      text={dataPR.keperluan ?? "-"}
                    />
                  </Col>
                  <Col md={2}>
                    <InfoItemDetail
                      label="Proyek"
                      text={dataPR.nama_proyek ?? "-"}
                    />
                  </Col>

                  <Col md={2}>
                    {tab === "history" && (
                      <InfoItemDetail
                        label="Status Approval"
                        text={
                          dataPR.status_approval === "APP"
                            ? "APPROVED"
                            : dataPR.status_approval === "VER"
                            ? "VERIVIED"
                            : dataPR.status_approval === "REV"
                            ? "REVISED"
                            : dataPR.status_approval === "REJ"
                            ? "REJECTED"
                            : "PENDING"
                        }
                      />
                    )}
                  </Col>
                </>
              ) : (
                <div className="ml-auto mr-auto">
                  <small>Memuat data...</small>
                </div>
              )}
            </Row>
          </Card.Body>
        </Card>
      </>
    )
  }
  const PageContent = () => {
    return (
      <div className="mb-2">
        <span style={{ fontSize: "14px" }}>
          <b>List Item Barang Purchase Request</b>
        </span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Barang</ThFixed>
              <Th style={{ minWidth: "300px" }}>Item Barang</Th>
              <Th style={{ minWidth: "200px" }}>Kelompok Barang</Th>
              <Th style={{ minWidth: "200px" }}>Jenis Barang</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty. Beli</Th>
            </Tr>
          </THead>
          <TBody>
            {isPageLoading === false ? (
              dataListPR.length > 0 ? (
                dataListPR.map((val, index) => (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item ?? "-"}</TdFixed>
                    <Td>{val.nama_item ?? "-"}</Td>
                    <Td>{val.nama_kelompok ?? "-"}</Td>
                    <Td>{val.nama_jenis ?? "-"}</Td>
                    <Td>{val.nama_satuan ?? "-"}</Td>
                    <Td>
                      <div className="text-right">
                        {decimalConvert(val.qty ?? 0)}
                      </div>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={8}>
                    <div className="text-center p-3">
                      <b>Tidak Ada Data</b>
                    </div>
                  </Td>
                </Tr>
              )
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <div className="d-flex justify-content-center">
                    <Spinner
                      animation="border"
                      size="sm"
                      className="mr-2 mt-1"
                    />
                    <small>Memuat data...</small>
                  </div>
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>
      </div>
    )
  }
  const HistoryCatatanApproval = () => {
    const InfoItem = ({
      labelHeader,
      karyawanName,
      labelCatatan,
      catatanValue
    }) => (
      <>
        <small>{labelHeader}</small>
        <p>
          <b>{karyawanName}</b>
        </p>
        <small>{labelCatatan}</small>
        <p>
          <b>{catatanValue}</b>
        </p>
      </>
    )
    return (
      <div className="mb-4">
        <div className="mt-4">
          <span style={{ fontSize: "14px" }}>
            <b>History Catatan Approval Purchase Request</b>
          </span>
        </div>
        <Card>
          <Card.Body>
            <Row>
              {dataPR.stakeholder === undefined ? (
                <Col className="text-center">
                  <small>Memuat Data...</small>
                </Col>
              ) : (
                dataPR.stakeholder.map((val, index) => (
                  <>
                    <Col md={3} key={index}>
                      <InfoItem
                        labelHeader={
                          val.status_approval === "APP"
                            ? "Pengesah"
                            : index === 0
                            ? "Pembuat"
                            : `Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                        }
                        karyawanName={val.nama_karyawan ?? "-"}
                        labelCatatan={
                          val.status_approval === "APP"
                            ? "Catatan Pengesah"
                            : index !== 0
                            ? `Catatan Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                            : ""
                        }
                        catatanValue={index !== 0 ? val.catatan : ""}
                      />
                    </Col>
                  </>
                ))
              )}
            </Row>
          </Card.Body>
        </Card>
        {/* <Card style={{height:"100px"}}>
          <Card.Body>
            <Row>
                {dataApproval.map((val, index) => (
                  <Col md={3} key={index}>
                    <InfoItem
                      labelHeader={val.catatan ?? "kosong"}
                      // karyawanName={val.nama_karyawan ?? "-"}
                      // labelCatatan={val.status_approval === "VER" ? `Catatan Pemeriksa ${val.approval_level}` : "Catatan Pengesah"}
                      // catatanValue={val.catatan ?? "-"}
                    />
                  </Col>
                ))}
            </Row>
          </Card.Body>
        </Card> */}
      </div>
    )
  }
  const CatatanApprovalPR = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty
  }) => {
    return (
      <>
        {tab !== "history" && (
          <div>
            <TextArea
              label={
                <span style={{ fontSize: "14px" }}>
                  <b>Catatan Approval Purchase Request</b>
                </span>
              }
              name="catatan"
              placeholder="Masukan catatan"
              style={{ height: "100px" }}
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: (
                            <span className="text-danger">
                              Reject Purchase Request
                            </span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                              Purchase Request
                            </span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: (
                            <span className="text-danger">
                              Reject Purchase Request
                            </span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: (
                            <span className="text-warning">
                              Revise Purchase Request
                            </span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                              Purchase Request
                            </span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadButtonSection>
            <BackButton onClick={() => history.goBack()} />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
      </CRUDLayout>
      <DetailPR />
      <PageContent />
      <TextEditor
        label={
          <span style={{ fontSize: "14px" }}>
            <b>Catatan Purchase Request</b>
          </span>
        }
        placeholder="Catatan Purchase Request"
        editorState={EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(dataPR?.catatan_purchase_request ?? "<p></p>")
          )
        )}
      />
      <HistoryCatatanApproval />
      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan")
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataPR.no_purchase_request,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataPR.baseline
          }
          console.log("finalValues", finalValues)

          PurchaseRequestApi.approve(finalValues)
            .then((res) => {
              console.log("res", res)
              history.push("/human-resource/approval/purchase-request", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${
                    modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                  } data berhasil!`
                }
              })
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: `Ubah data gagal! (${err.response.data.message})`
              })
            })
            .finally(() => setModalConfig({ show: false }))
        }}>
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty
        }) => (
          <>
            <GuestMiddleware>
              <CatatanApprovalPR
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                validateForm={validateForm}
                dirty={dirty}
              />
            </GuestMiddleware>
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  )
}

export default DetailPurchaseRequest
