import { PurchaseOrderAsetApi } from "api"
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  Table,
  TBody,
  Td,
  TdFixed,
  TextArea,
  Th,
  THead,
  ThFixed,
  Tr
} from "components"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card, Col, Modal, Row } from "react-bootstrap"
import { IoEyeOutline } from "react-icons/io5"
import { useHistory, useLocation } from "react-router-dom"
import {
  DateConvert,
  DecimalConvert,
  GuestMiddleware,
  RupiahConvert
} from "utilities"
import * as Yup from "yup"
import { ModalSeleksiVendor } from "./Section"

const DetailApprovalPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory()
  const { state } = useLocation()
  const no_po = state?.no_po
  const tab = state?.tab ?? ""
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [dataPO, setDataPO] = useState([])
  const [approveStatus, setApproveStatus] = useState("V")
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: ""
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    PurchaseOrderAsetApi.getSingle({ no_purchase_order_aset: state.no_po })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V"
        setDataPO(data.data)
        setApproveStatus(behavior.toUpperCase())
      })
      .catch(() => {
        setIsFetchingFailed(true)
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!"
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const whenNoPurchaseOrder = () => {
    setIsPageLoading(false)
    setIsFetchingFailed(true)
  }

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10)

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV"
      }

      if (modalConfig.type === "approve") {
        return "APP"
      }

      if (modalConfig.type === "reject") {
        return "REJ"
      }
    }

    const finalValues = {
      no_transaksi: state.no_po,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataPO.detail.baseline
    }

    PurchaseOrderAsetApi.save(finalValues)
      .then(() =>
        history.push("/human-resource/approval/purchase-order-aset", {
          alert: {
            show: true,
            variant: "primary",
            text: "Approval berhasil disimpan!"
          }
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!"
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false
        })
        getInitialData()
      })
  }

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value)
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber))

    return new Intl.NumberFormat("id-ID").format(checkConvert)
  }

  useEffect(() => {
    setNavbarTitle("Purchase Order")
    no_po ? getInitialData() : whenNoPurchaseOrder()

    return () => {
      setIsPageLoading(false)
    }
  }, [])

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    )

    return (
      <Row className="mb-3">
        <Col>
          <table>
            <tbody>
              <InfoItem
                title="Tgl. Purchase Order Aset"
                value={
                  dataPO?.detail?.tgl_purchase_order_aset
                    ? DateConvert(
                        new Date(dataPO?.detail?.tgl_purchase_order_aset)
                      ).detail
                    : "-"
                }
              />
              <InfoItem
                title="No. Purchase Order Aset"
                value={
                  dataPO?.detail?.no_purchase_order_aset
                    ? dataPO?.detail?.no_purchase_order_aset
                    : "-"
                }
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table>
            <tbody>
              <InfoItem title="Kepada" />
              <span>
                {dataPO?.detail?.nama_vendor
                  ? dataPO?.detail?.nama_vendor
                  : "-"}
              </span>
              <br />
              <span>
                {dataPO?.detail?.alamat_vendor
                  ? dataPO?.detail?.alamat_vendor
                  : "-"}
              </span>
              <br />
              <span>
                {dataPO?.detail?.contact_person
                  ? dataPO?.detail?.contact_person
                  : "-"}
              </span>
            </tbody>
          </table>
        </Col>
      </Row>
    )
  }

  const InfoItems = ({ title, value }) => (
    <tr>
      <td width={200}>{title}</td>
      <td className="pr-2">:</td>
      <td>{value}</td>
    </tr>
  )

  let total = 0
  let nilaiDiskon = 0
  let setelahDiskon = 0
  let nilaiPPN = 0
  let setelahPPN = 0
  let nilaiDP = 0
  let totalBiayaAngkut = 0
  let grandTotal = 0

  dataPO?.detail?.detail?.map((val, index) => {
    const jumlah = parseFloat(val.harga_kesepakatan)
    const jumlahBiayaAngkut = parseFloat(
      val.harga_kesepakatan_biaya_angkut ?? 0
    )

    totalBiayaAngkut = totalBiayaAngkut + jumlahBiayaAngkut
    total = total + jumlah
    nilaiDiskon = dataPO.detail?.diskon
      ? parseFloat(dataPO.detail?.diskon) === 0
        ? 0
        : parseFloat((dataPO.detail?.diskon / 100) * total)
      : 0
    setelahDiskon = parseFloat(total - nilaiDiskon)
    nilaiPPN = dataPO.detail?.ppn
      ? parseFloat(dataPO.detail?.ppn) === 0
        ? 0
        : parseFloat((dataPO.detail?.ppn / 100) * setelahDiskon)
      : 0
    setelahPPN = parseFloat(setelahDiskon + nilaiPPN)

    grandTotal = setelahPPN + totalBiayaAngkut
    nilaiDP = dataPO.detail?.dp
      ? parseFloat(dataPO.detail?.dp) === 0
        ? 0
        : parseFloat((dataPO.detail?.dp / 100) * grandTotal)
      : 0
  })

  const CatatanSection = () => (
    <div>
      <div className="mt-3 mb-3">
        Pesanan ini selambat-lambatnya telah sampai pada :
      </div>
      <Row>
        <Col>
          <table>
            <tbody>
              <InfoItems
                title="Tanggal"
                value={
                  dataPO.detail.tgl_pengiriman
                    ? DateConvert(new Date(dataPO.detail.tgl_pengiriman)).detail
                    : "-"
                }
              />
              <InfoItems title="Pembayaran" value={dataPO.detail.pembayaran} />
              <InfoItems
                title="Dikirim ke"
                value={dataPO.detail.alamat_tujuan}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <table>
              <tbody>
                <InfoItems
                  title="Sub Total"
                  value={
                    RupiahConvert(parseFloat(total).toString()).getWithComa
                  }
                />
                <InfoItems
                  title={`Diskon ${
                    DecimalConvert(dataPO.detail.diskon ?? 0).getWithComa
                  } %`}
                  value={RupiahConvert(nilaiDiskon.toString()).getWithComa}
                />
                <InfoItems
                  title="Harga Setelah DIskon"
                  value={
                    RupiahConvert(parseFloat(setelahDiskon).toString())
                      .getWithComa
                  }
                />
                <InfoItems
                  title={`PPN ${
                    DecimalConvert(dataPO.detail.ppn ?? 0).getWithComa
                  } %`}
                  value={RupiahConvert(nilaiPPN.toString()).getWithComa}
                />
                <InfoItems
                  title="Harga Setelah Pajak"
                  value={
                    RupiahConvert(parseFloat(setelahPPN).toString()).getWithComa
                  }
                />
                <InfoItems
                  title="Biaya Angkut"
                  value={
                    RupiahConvert(parseFloat(totalBiayaAngkut).toString())
                      .getWithComa
                  }
                />
                <InfoItems
                  title="Grand Total"
                  value={
                    RupiahConvert(parseFloat(grandTotal).toString()).getWithComa
                  }
                />
                <InfoItems
                  title={`DP ${
                    DecimalConvert(dataPO.detail.dp ?? 0).getWithComa
                  } %`}
                  value={
                    RupiahConvert(parseFloat(nilaiDP).toString()).getWithComa
                  }
                />
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  )

  const ListItemPurchaseOrderSection = () => {
    const [modalDetailSelesiVendorDetail, setModalDetailSelesiVendorDetail] =
      useState({ id: "", show: false })

    return (
      <>
        {modalDetailSelesiVendorDetail?.show && (
          <ModalSeleksiVendor
            setModalConfig={setModalDetailSelesiVendorDetail}
            modalConfig={modalDetailSelesiVendorDetail}
          />
        )}
        <div className="py-2 pl-1">
          <span>Kami tempatkan order pembelian dengan sbb :</span>
        </div>
        {dataPO?.detail?.detail ? (
          dataPO?.detail?.detail.length > 0 ? (
            <>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <Th>Nama Item Aset</Th>
                    <Th>Keperluan</Th>
                    <Th>Harga Kesepakatan</Th>
                    <Th>Vendor Angkut</Th>
                    <Th>Harga Kesepakatan Biaya Angkut</Th>
                    <Th>Jumlah</Th>
                    <Th>Aksi</Th>
                  </Tr>
                </THead>
                <TBody>
                  {dataPO?.detail?.detail.map((val, index) => {
                    const jumlah = parseFloat(val.harga_kesepakatan)

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <Td>
                          <div style={{ width: "200px" }}>{val.nama_aset}</div>
                        </Td>
                        <Td className="text-capitalize">
                          {val.keperluan ?? "-"}
                        </Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(
                              parseFloat(val.harga_kesepakatan ?? 0).toString()
                            ).getWithComa
                          }
                        </Td>
                        <Td>{val?.nama_vendor_angkut ?? "-"}</Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(
                              parseFloat(
                                val?.harga_kesepakatan_biaya_angkut ?? 0
                              ).toString()
                            ).getWithComa
                          }
                        </Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(parseFloat(jumlah).toString())
                              .getWithComa
                          }
                        </Td>
                        <Td className="text-center">
                          <ActionButton
                            size="sm"
                            className="mr-1"
                            text={<IoEyeOutline />}
                            onClick={() => {
                              setModalDetailSelesiVendorDetail({
                                id: val.id_seleksi_vendor,
                                show: true,
                                data: val
                              })
                            }}
                          />
                        </Td>
                      </Tr>
                    )
                  })}
                </TBody>
              </Table>
              <CatatanSection />
              <div>
                <span>Keterangan :</span>
                <p>{dataPO.detail.keterangan}</p>
              </div>
            </>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </>
    )
  }

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }>
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    )
  }

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty
  }) => {
    const dataPengaju = dataPO?.approval ? dataPO.approval : []
    const dataDetail = dataPO.detail ? dataPO.detail : "-"

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Purchase Order</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPengaju.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER"
                      ? `Pemeriksa ${val.approval_level}`
                      : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          {dataPengaju.length > 1 && <hr />}
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {dataDetail.status_approval === "VER" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Purchase Order
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Purchase Order
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Purchase Order
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "revise",
                            title: (
                              <span className="text-warning">
                                Revise Purchase Order
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Purchase Order
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    )
  }

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Purchase Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <ListItemPurchaseOrderSection />
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan Catatan")
          })}
          onSubmit={formSubmitHandler}>
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            validateForm,
            dirty
          }) => (
            <>
              <GuestMiddleware>
                <FormCard
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  validateForm={validateForm}
                  dirty={dirty}
                />
              </GuestMiddleware>
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
    </>
  )
}

export default DetailApprovalPurchaseOrder
