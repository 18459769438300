import { ProfilApi } from "api"
import { ActionButton, Alert, Input, Select } from "components"
import { Formik } from "formik"
import _ from "lodash"
import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { GuestMiddleware } from "utilities"
import * as Yup from "yup"

const FormDataPendidikan = ({ dataKaryawan, dataJenjangPendidikan }) => {
  const [showAlert, setShowAlert] = useState({
    show: false,
    text: "",
    variant: "primary"
  })

  const formInitialValues = {
    id_karyawan: dataKaryawan.id_karyawan,
    id_jenjang_pendidikan:
      dataKaryawan.id_jenjang_pendidikan == null
        ? ""
        : dataKaryawan.id_jenjang_pendidikan,
    lembaga_pendidikan:
      dataKaryawan.lembaga_pendidikan == null
        ? ""
        : dataKaryawan.lembaga_pendidikan,
    tahun_selesai:
      dataKaryawan.tahun_selesai == null ? "" : dataKaryawan.tahun_selesai
  }

  const formValidationSchema = Yup.object().shape({
    id_jenjang_pendidikan: Yup.string().required("Pilih jenjang pendidikan"),
    lembaga_pendidikan: Yup.string().required("Masukan lembaga pendidikan"),
    tahun_selesai: Yup.string().required("Masukan tahun selesai")
  })

  const formSubmitHandler = (values, { setSubmitting }) => {
    ProfilApi.updateDataPendidikan({
      id_karyawan: values.id_karyawan,
      id_jenjang_pendidikan: values.id_jenjang_pendidikan,
      lembaga_pendidikan: values.lembaga_pendidikan,
      tahun_lulus: values.tahun_selesai
    })
      .then(() => {
        setShowAlert({
          show: true,
          variant: "primary",
          text: "Ubah data sukses!"
        })
      })
      .catch((err) => {
        setShowAlert({
          show: true,
          variant: "danger",
          text: `Ubah data gagal! (${err})`
        })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}>
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit
        }) => (
          <form className="py-2" onSubmit={handleSubmit}>
            <Alert
              show={showAlert.show}
              showCloseButton={true}
              text={showAlert.text}
              variant={showAlert.variant}
              onClose={() =>
                setShowAlert({
                  ...showAlert,
                  show: false
                })
              }
            />
            <Row>
              <Col lg>
                <Select
                  label="Jenjang Pendidikan"
                  name="id_jenjang_pendidikan"
                  defaultValue={values.id_jenjang_pendidikan}
                  error={
                    errors.id_jenjang_pendidikan &&
                    touched.id_jenjang_pendidikan &&
                    true
                  }
                  errorText={errors.id_jenjang_pendidikan}
                  onChange={handleChange}>
                  <option value="">Pilih jenjang pendidikan</option>
                  {dataJenjangPendidikan.map((val, index) => (
                    <option key={index} value={val.id_jenjang_pendidikan}>
                      {val.nama_jenjang_pendidikan}
                    </option>
                  ))}
                </Select>
              </Col>
              <Col lg>
                <Select
                  label="Tahun Lulus"
                  name="tahun_selesai"
                  defaultValue={values.tahun_selesai}
                  error={errors.tahun_selesai && touched.tahun_selesai && true}
                  errorText={errors.tahun_selesai}
                  onChange={handleChange}>
                  <option value="">Pilih tahun selesai</option>
                  {_.range(new Date().getFullYear(), 1950 - 1).map(
                    (res, index) => (
                      <option key={index} value={res}>
                        {res}
                      </option>
                    )
                  )}
                </Select>
              </Col>
            </Row>
            <Input
              type="text"
              label="Lembaga Pendidikan"
              placeholder="Masukan lembaga pendidikan"
              name="lembaga_pendidikan"
              value={values.lembaga_pendidikan}
              error={
                errors.lembaga_pendidikan && touched.lembaga_pendidikan && true
              }
              errorText={errors.lembaga_pendidikan}
              onChange={handleChange}
            />
            <GuestMiddleware>
              <div className="d-flex justify-content-end mt-3">
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah Data Pendidikan"
                  loading={isSubmitting}
                />
              </div>
            </GuestMiddleware>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormDataPendidikan
