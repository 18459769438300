import { InfoPeluangApi } from "api"
import {
  ActionButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
  TextArea,
  TextEditor
} from "components"
import { ContentState, convertFromHTML, EditorState } from "draft-js"
import { Formik } from "formik"
import { useEffect, useState } from "react"
import { Card, Col, Modal, Row } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"
import { DateConvert, GuestMiddleware } from "utilities"
import * as Yup from "yup"

const DetailPeluang = ({ setNavbarTitle }) => {
  const title = "Info Peluang"
  const history = useHistory()
  const { state } = useLocation()
  const { tab, no_info_peluang, status_info_peluang } = state || {}
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [approveStatus, setApproveStatus] = useState("V")
  const [dataApproval, setDataApproval] = useState([])
  const [data, setData] = useState([{ detail: [] }])
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: ""
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: ""
  })
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty()
  })

  const getData = () => {
    setIsPageLoading(true)

    InfoPeluangApi.getSingle({ no_info_peluang, status_info_peluang })
      .then((res) => {
        const behavior = String(res?.data?.data?.behavior).toUpperCase()

        setData(res?.data?.data ?? {})
        setDataApproval(res?.data?.data?.detail?.stakeholder ?? [])
        setApproveStatus(behavior ?? "V")
      })
      .catch((err) => alert("Data gagal dimuat!"))
      .finally(() => setIsPageLoading(false))
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle("Detail " + title) // SET JUDUL NAVBAR
    getData() // GET DATA DARI SERVER
  }, [])

  useEffect(() => {
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(data?.detail?.keterangan ?? "<p></p>")
        )
      )
    )
  }, [data])

  const InfoSection = ({ data }) => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    )

    const ShowData = ({ title, text, line }) => (
      <div>
        <small
          className="text-secondary"
          style={{ opacity: 0.9, textTransform: "capitalize" }}>
          {title}
        </small>
        <div className="text-dark">
          <div>{text ? text : "-"}</div>
        </div>

        {line && <hr className="my-2" />}
      </div>
    )

    return (
      <>
        <Row>
          <Col lg>
            <InfoItem
              title="Tgl. Info Peluang"
              value={
                data.tgl_info_peluang
                  ? DateConvert(new Date(data.tgl_info_peluang)).detail
                  : "-"
              }
            />
            <InfoItem
              title="No. Info Peluang"
              value={data.no_info_peluang ?? "-"}
            />
            <InfoItem title="Customer" value={data.nama_customer ?? "-"} />
            <InfoItem title="Proyek" value={data.nama_proyek ?? "-"} />
          </Col>
          <Col lg>
            <InfoItem
              title="Nama Marketing"
              value={data.nama_marketing ?? "-"}
            />
            <InfoItem title="Jabatan" value={data.nama_jabatan ?? "-"} />
            <InfoItem
              title="Unit Organisasi"
              value={data.nama_unit_organisasi ?? "-"}
            />
          </Col>
        </Row>
        <hr />
        <div className="mb-3">
          <div>Status Info Peluang</div>
          <div
            className={
              data.status_info_peluang === "1"
                ? "text-warning"
                : data.status_info_peluang === "2"
                ? "text-success"
                : "text-primary"
            }>
            <b>{String(data.status_peluang ?? "-").toUpperCase()}</b>
          </div>
          <div>
            {DateConvert(new Date(data.tgl_status_info_peluang)).custom}
          </div>
        </div>
        <TextEditor
          readOnly
          options={[]}
          label="Keterangan"
          editorState={textEditorState}
        />
      </>
    )
  }

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.type === "APP"
                ? "Approve"
                : modalConfig.type === "VER"
                ? "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? "Approve"
                : modalConfig.type === "VER"
                ? "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "VER"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }>
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    )
  }

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty
  }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Info Peluang</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.length > 0
              ? dataApproval.map((val, index) => (
                  <InfoItem
                    key={index}
                    title1={
                      val.status_approval === "VER"
                        ? `PEMERIKSA ${val.approval_level}`
                        : val.status_approval === "PEN"
                        ? "PENGAJU"
                        : "PENGESAH"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2={"Catatan"}
                    value2={val.catatan ?? "-"}
                  />
                ))
              : ""}
          </div>
          {tab !== "history" && (
            <>
              {" "}
              <hr />
              <div>
                <TextArea
                  label="Catatan"
                  name="catatan"
                  placeholder="Masukan catatan"
                  value={values.catatan}
                  onChange={handleChange}
                  error={errors.catatan && true}
                  errorText={errors.catatan}
                />
                <div className="d-flex justify-content-end mt-3">
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: (
                            <span className="text-danger">
                              Reject Info Peluang
                            </span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />

                  {approveStatus !== "A" && (
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REV",
                            title: (
                              <span className="text-warning">
                                Revise Info Peluang
                              </span>
                            )
                          })
                        }
                        validateForm()
                      }}
                    />
                  )}

                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: approveStatus === "A" ? "APP" : "VER",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                              Info Peluang
                            </span>
                          )
                        })
                      }
                      validateForm()
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    )
  }

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card className="pb-3 mt-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data {title}</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          {isPageLoading ? (
            <DataStatus loading={isPageLoading} text="Memuat Data" />
          ) : (
            <>
              <InfoSection data={data?.detail} />
              <hr />
            </>
          )}
        </Card.Body>
      </Card>

      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan Catatan")
          })}
          onSubmit={(values, { setSubmitting }) => {
            const finalValues = {
              ...values,
              no_transaksi: data?.detail?.no_info_peluang,
              status_approval: modalConfig.type,
              tgl_approval: DateConvert(new Date()).default,
              approval_baseline: data?.detail?.status_info_peluang
            }

            InfoPeluangApi.approve(finalValues)
              .then((res) => {
                history.push("/human-resource/approval/info-peluang", {
                  alert: {
                    show: true,
                    variant: "primary",
                    text: `${
                      modalConfig.type == "APP"
                        ? "Approve"
                        : modalConfig.type == "REV"
                        ? "Revise"
                        : modalConfig.type === "VER"
                        ? "Verify"
                        : "Reject"
                    } data berhasil!`
                  }
                })

                // const logValues = {
                //   no_transaksi: data.no_rae,
                //   nama_transaksi: "Info Peluang",
                //   baseline: data.status_info_peluang,
                //   status_approval: modalConfig.type,
                //   no_transaksi_ref: data.no_peluang,
                //   baseline_ref: data.baseline,
                //   keterangan_transaksi:
                //     modalConfig.type === "APP"
                //       ? "Approve Info Peluang"
                //       : modalConfig.type === "VER"
                //       ? "Verifikasi Info Peluang"
                //       : "Reject Info Peluang",
                // };
                // InfoPeluangApi.saveLogProgess(logValues);
              })
              .catch((err) => {
                setAlertConfig({
                  show: true,
                  variant: "danger",
                  text: `Simpan approval gagal! (${
                    err?.response?.data?.message ?? ""
                  })`
                })
              })
              .finally(() => setModalConfig({ show: false }))
          }}>
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            validateForm,
            dirty
          }) => (
            <>
              <GuestMiddleware>
                <FormCard
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  validateForm={validateForm}
                  dirty={dirty}
                />
              </GuestMiddleware>
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
      )}
    </CRUDLayout>
  )
}
export default DetailPeluang
